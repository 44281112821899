import { merge } from 'ramda'
import { removePhoneMask } from 'utils/formatPhone'
import { formatDate } from 'utils/dateUtils'
import { DateTime } from 'luxon'
import capitalize from 'utils/capitalize'
import * as storage from 'utils/storage'
import {
  METER_LOOKUP_SUCCESS,
  METER_LOOKUP_FAILURE,
  SUBMIT_SERVICE_ADDRESS_FORM,
  SUBMIT_SELECT_ADDRESS_FORM,
  SUBMIT_PERSONAL_INFO_FORM,
  SAVE_SERVICE_ADDRESS_VALUES,
  SAVE_SELECT_ADDRESS_VALUES,
  SAVE_PERSONAL_INFO_VALUES,
  SAVE_GENERAL_PERSONAL_INFO_VALUES,
  SET_SHOULD_REDIRECT_TO_PAYMENT,
  ENROLL_SUCCESS,
  ENROLL_FAILURE,
  SUBMIT_UPDATE_REFERRAL_NAME,
  SAVE_REFERRAL_COOKIE_INFO,
  UPDATE_ENROLLMENT_SUCCESS,
  RETRIEVE_ENROLLMENT,
  RETRIEVE_ENROLLMENT_SUCCESS,
  VALIDATE_ENROLLMENT_SUCCESS,
  SUBMIT_GET_DOCUMENT_FOR_SIGNATURE_SUCCESS,
  TDSP_OFFERS_SUCCESS,
  TDSP_OFFERS_FAILURE,
  SELECT_OFFER,
  WRONG_TDSP_ERROR,
  DEPOSIT_REQUIREMENT,
  SUBMIT_ZIPCODE_FORM,
  SAVE_REFERRAL_INFO,
  SAVE_PROMO_CODE,
  GET_USER_SUCCESS,
  LOGIN_SUCCESS,
  SUBMIT_CREDIT_AUTH,
  USER_ALREADY_EXISTS,
  GET_CHASE_PAYMENT_UID_SUCCESS,
  UPDATE_ENROLLMENT_FAILURE,
  SUBMIT_LOGIN_FORM,
  SUBMIT_CHASE_PAYMENT_CUSTOM_FORM,
  NOT_VALID_PRODUCT,
  METER_ALREADY_CONNECTED,
  VERIFY_EMAIL_FAILURE,
  SET_AUTO_PAY,
  SUBMIT_NO_PAYMENT_REQUIRED_FORM,
  SUBMIT_RENEWAL_NO_PAYMENT_REQUIRED_FORM,
  SUBMIT_GENERAL_SERVICE_ADDRESS_FORM,
  SET_ENROLLMENT_TYPE,
  SUBMIT_VERIFY_EMAIL_FORM,
  SET_VALIDATION_ERROR,
  COMPLETE_ENROLLMENT,
  COMPLETE_ENROLLMENT_SUCCESS,
  COMPLETE_ENROLLMENT_FAILURE,
  PRODUCT_OFFER_SUCCESS,
  PRODUCT_OFFER_FAILURE,
  TOGGLE_IS_VALIDATING_ACCOUNT,
  SAVE_PARAMETERS,
  CAPTURE_SIGNATURE,
  GET_LMI_PROGRAM_TYPES,
  SUBMIT_CAPTURE_SIGNATURE_SUCCESS
} from './constants'

import { CHANGE_LOCALE } from 'modules/language/constants'

import {
  RESET_PROMO,
  UPDATE_PROMO,
  ERROR_PROMO,
} from 'modules/promos/constants'
import { PAYMENT_PROVIDERS, DS_REFERRAL_MAIN_PAGE_URL } from 'config'
import { companyInfo } from 'config/profile'
import { features } from 'config/profile'
import ctBillingCycles from 'data/ct_billing_cycles.json'
import { lplDuns } from 'config'

export const initialState = {
  error: '',
  meterID: '',
  metersForLocation: [],
  personalInformation: {},
  serviceInformation: {},
  serviceAddress: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    postal_code: '',
  },
  summerLock: false,
  summerLockUpdated: false,
  shouldRedirectToPayment: false,
  finished: false,
  loading: true,
  promo: {},
  referralInfo: {
    fullName: '',
    referralName: '',
    referralCode: '',
    referralType: '',
    nameFromCookie: '',
    updatedName: '',
    eligibleStates: [],
    restrictions:[]
  },
  tdspsWithOffers: [],
  inValidProduct: false,
  meterAlreadyConnected: false,
  isValidatingAccount: false,
  enrollment: {
    id: '',
    email: '',
    phone: '',
    enrollmentType: 'new',
    createdBy: 'customer',
    serviceType: '',
    formData: {
      ssn: '',
      payment: {
        token: '',
        provider: '',
        merchantID: companyInfo.chaseMerchantID,
      },
      agreeToCreditCheck: false,
      marketingOptIn: true,
      paperlessOptIn: true,
      ofAgeAndlegallyAuthorized: false,
      language: storage.getLang(),
      promoCode: '',
      autopay: false,
      send_deposit_notification: true,
      send_enrollment_complete_notification: true,
      premise_type: 'residential',
      company_name: '',
      birthdate: '2000-01-01'
    },
  },
  depositAmount: 150,
  isGeneralEnrollment: null,
  completeEnrollment: false,
  bills: [],
  documents: [],
  parameters: {},
  signature: "",
  lmiPrograms: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SERVICE_ADDRESS_VALUES:
    case SUBMIT_SERVICE_ADDRESS_FORM:
      return merge(state, { serviceAddress: action.payload, meterID: '' })

    // case SAVE_GENERAL_SERVICE_ADDRESS_VALUES: // todo consider adding this later if needed
    case SUBMIT_GENERAL_SERVICE_ADDRESS_FORM: {
      const {
        meterIdentifier,
        meterIdentifier2,
        city,
        line1,
        line2,
        postal_code,
        nameKey,
        billingCycle,
        bills,
        documents,
        avgMonthlyBill,
        is_lmi,
        lmi_self_attestation,
        phone,
        birthday,
        isSameBillingAddress,
        billingLine1,
        billlingLine2,
        billingCity,
        billingState,
        billingPostalCode,
        ssnLast4,
      } = action.payload
      let requestedStartDate = state.enrollment.formData.requestedStartDate
      const stateCode = state.enrollment.formData.tdsp.state_code
      const tdspName = state.enrollment.formData.tdsp.name

      const billingAddress = isSameBillingAddress
      ? { line1, line2, city, state: state.enrollment.formData.tdsp.state_code, postal_code }
      : { line1: billingLine1, line2: billlingLine2, city: billingCity, state: billingState, postal_code: billingPostalCode }

      const today = new Date(Date.now())
      today.setDate(today.getDate() + 3)
      const searchDate = new Date(today).toISOString().slice(0, 10)
      if (
        typeof requestedStartDate === 'undefined' ||
        requestedStartDate === ''
      ) {
        if (stateCode === 'CT' && ctBillingCycles[tdspName]) {
          // sort by ascending dates
          let nextMeterRead = ctBillingCycles[tdspName].sort((a, b) => {
            return new Date(a.date) - new Date(b.date)
          })
          // filter by billingCycle
          let nextMeterReadFiltered = nextMeterRead.filter(tdsp => {
            if (String(tdsp.billingcycle) === billingCycle) {
              return true
            }
            return false
          })

          // find next meter read date from today + 5
          // list should be small at this point no need to optimize
          for (let i = 0; i < nextMeterReadFiltered.length; i++) {
            let tdsp = nextMeterReadFiltered[i]
            let meterReadDate = new Date(tdsp.date).toISOString().slice(0, 10)

            if (searchDate < meterReadDate) {
              requestedStartDate = meterReadDate
              break
            }
          }

          // if cloest meter date is still not found
          if (requestedStartDate === '') {
            requestedStartDate = searchDate
          }
        } else {
          // for non CT state use default
          requestedStartDate = searchDate
        }
      }

      return merge(state, {
        meterID: meterIdentifier,
        bills,
        documents,
        enrollment: {
          ...state.enrollment,
          phone: `+1${removePhoneMask(phone)}`, // assuming US only for now
          formData: {
            ...state.enrollment.formData,
            meterUID: meterIdentifier,
            meterUID2: meterIdentifier2,
            birthdate: formatDate(DateTime.fromFormat(birthday, 'MM/dd/yyyy')),
            address: {
              line1: line1,
              line2: line2,
              city: city,
              state: state.enrollment.formData.tdsp.state_code,
              postal_code: postal_code,
            },
            billingAddress,
            avgMonthlyBill: avgMonthlyBill && avgMonthlyBill.toString(),
            nameKey,
            billingCycle,
            requestedStartDate,
            is_lmi,
            lmi_self_attestation,
            ssnLast4,
          },
        },
      })
    }

    case METER_LOOKUP_SUCCESS:
      return merge(state, {
        meterID: initialState.meterID,
        metersForLocation: action.payload,
      })

    case SAVE_SELECT_ADDRESS_VALUES: {
      const { payload } = action
      if (!payload) return state
      const { esi } = payload

      if (!esi) return state

      const { address, premise } = state.metersForLocation.find(
        location => location.esi === esi
      )

      let premise_type = ''
      if (premise === 'premise_residential' || premise === '') {
        premise_type = 'residential'
      } else {
        premise_type = 'commercial'
      }

      return merge(state, {
        meterID: esi,
        enrollment: {
          ...state.enrollment,
          formData: {
            ...state.enrollment.formData,
            meterUID: esi,
            address: {
              line1: capitalize(address.line1),
              line2: address.line2,
              city: capitalize(address.city),
              state: address.state,
              postal_code: address.postal_code,
            },
            premise_type: premise_type,
          },
        },
      })
    }

    case SUBMIT_SELECT_ADDRESS_FORM: {
      const { payload } = action
      if (!payload) return state
      let {
        esi,
        enrollMode,
        firstAvailableDate,
        requestedStartDate,
        confirmResidence,
      } = payload
      
      if (!esi) return state

      const {meter_read_cycle, address } = state.metersForLocation.find(
        location => location.esi === esi
      )
      let formattedDate = requestedStartDate
      if (requestedStartDate) {
        formattedDate = formatDate(requestedStartDate)
      }

      //lubbock hack
      const meterCycleStartLookup = {
        '02':	'2024-03-04',
        '03':	'2024-03-05',
        '04':	'2024-03-06',
        '05':	'2024-03-07',
        '06':	'2024-03-08',
        '07':	'2024-03-11',
        '08':	'2024-03-12',
        '09':	'2024-03-13',
        '10':	'2024-03-14',
        '11':	'2024-03-15',
        '12':	'2024-03-18',
        '13':	'2024-03-19',
        '14':	'2024-03-20',
        '15':	'2024-03-21',
        '16':	'2024-03-22',
        '17':	'2024-03-25',
        '18':	'2024-03-26',
        '20':	'2024-03-28',
        '21':	'2024-03-29',
        '01':	'2024-04-01',
      }      
      
      if (state.enrollment.formData.tdsp.duns_number === lplDuns) {        
        const meterReadDate = meterCycleStartLookup[meter_read_cycle]
        //add one day to meterReadDate
        const date = new Date(meterReadDate)
        // check if date is in the future
        const today = new Date()
        if (date > today) {
          enrollMode = 'movein'
          requestedStartDate = date.toISOString().split('T')[0]
          formattedDate=formatDate(requestedStartDate)
        }        
      }

      return merge(state, {
        meterID: esi,
        enrollment: {
          ...state.enrollment,
          formData: {
            ...state.enrollment.formData,
            meterUID: esi,
            enrollMode,
            firstAvailableDate,
            requestedStartDate: formattedDate,
            criticalCare: !confirmResidence,
            address: {
              line1: capitalize(address.line1),
              line2: address.line2,
              city: capitalize(address.city),
              state: address.state,
              postal_code: address.postal_code,
            },
          },
        },
      })
    }
    
    case SAVE_GENERAL_PERSONAL_INFO_VALUES: {
      const {
        email,
        first_name,
        last_name,
        password,
        premise_type,
      } = action.payload

      let { company_name, title } = '' // defined for commerical only
      if (premise_type === 'commercial') {
        company_name = action.payload.company_name
        title = action.payload.title
      }

      return merge(state, {
        personalInformation: action.payload,
        serviceAddress: {
          ...state.serviceAddress,
          state: state.enrollment.formData.tdsp.state_code,
        },
        enrollment: {
          ...state.enrollment,
          email,
          formData: {
            ...state.enrollment.formData,
            address: {
              line1: '',
              line2: '',
              city: '',
              state: state.enrollment.formData.tdsp.state_code,
              postal_code: state.serviceAddress.postal_code,
            },
            firstName: first_name,
            lastName: last_name,
            password,
            userAgreement: true,
            memberAgreement: true,
            company_name,
            premise_type,
            title,
          },
        },
      })
    }


    case SAVE_PERSONAL_INFO_VALUES:
    case SUBMIT_PERSONAL_INFO_FORM: {
      const {
        email,
        first_name,
        last_name,
        birthday,
        phone,
        ssn,
        isSameBillingAddress,
        line1,
        line2,
        city,
        state: a_state,
        postal_code,
        password,
        ssnLast4,
      } = action.payload

      let { company_name, title } = '' // defined for commerical only
      if (state.enrollment.formData.premise_type === 'commercial') {
        company_name = action.payload.company_name
        title = action.payload.title
      }

      const billingAddress = isSameBillingAddress
        ? state.enrollment.formData.address
        : { line1, line2, city, state: a_state, postal_code }

      // Add five digit zero padding to the last four ssn digits
      let socialNumber = ssn
      if (socialNumber && socialNumber !== '' && !features.REQUIRE_FULL_SOCIAL)
        socialNumber = `00000${socialNumber}`

      return merge(state, {
        personalInformation: action.payload,
        enrollment: {
          ...state.enrollment,
          phone: `+1${removePhoneMask(phone)}`, // assuming US only for now
          email,
          formData: {
            ...state.enrollment.formData,
            firstName: first_name,
            lastName: last_name,
            birthdate: formatDate(DateTime.fromFormat(birthday, 'MM/dd/yyyy')),
            billingAddress,
            ssn: removePhoneMask(socialNumber),
            password,
            userAgreement: true,
            memberAgreement: true,
            agreeToCreditCheck:
              ssn && removePhoneMask(socialNumber).length === 9 ? true : false,
            isSameBillingAddress,
            ssnLast4,
            company_name,
            title,
          },
        },
      })
    }

    case SUBMIT_CREDIT_AUTH: {
      const { ssn } = action.payload
      // Add five digit zero padding to the last four ssn digits
      let socialNumber = ssn
      if (socialNumber && socialNumber !== '' && !features.REQUIRE_FULL_SOCIAL)
        socialNumber = `00000${socialNumber}`

      return merge(state, {
        personalInformation: action.payload,
        enrollment: {
          ...state.enrollment,
          formData: {
            ...state.enrollment.formData,
            ssn: removePhoneMask(socialNumber),
            agreeToCreditCheck:
              ssn && removePhoneMask(socialNumber).length === 9 ? true : false,
          },
        },
      })
    }

    case UPDATE_ENROLLMENT_SUCCESS: {
      const { enrollmentID, finished, verificationStatus } = action.payload
      return merge(state, {
        error: '',
        loading: false,
        finished,
        enrollment: {
          ...state.enrollment,
          id: enrollmentID,
          verificationStatus,
        },
      })
    }
    case RETRIEVE_ENROLLMENT: {
      return merge(state, {
        loading: true,
      })
    }
    case RETRIEVE_ENROLLMENT_SUCCESS: {
      return merge(state, {
        error: '',
        loading: false,
        enrollment: {
          ...state.enrollment,
          ...action.payload,
          formData: {
            ...state.enrollment.formData,
            ...action.payload.formData,
            tdsp: state.enrollment.formData.tdsp,
          },
        },
      })
    }

    case VALIDATE_ENROLLMENT_SUCCESS: {
      return merge(state, {
        error: '',
        loading: false,
        enrollment: {
          ...state.enrollment,
          formData: {
            ...state.enrollment.formData,
            product: {
              ...state.enrollment.formData.product,
              ...action.payload.formData.product
            },
          },
        },
      })
    }

    case SUBMIT_GET_DOCUMENT_FOR_SIGNATURE_SUCCESS:{
      return merge(state, {
        error: '',
        loading: false,
        documentSignatureUrl: action.payload.signingURL,
      })
    }

    case COMPLETE_ENROLLMENT: {
      return merge(state, {
        loading: true,
        error: ''
      })
    }

    case COMPLETE_ENROLLMENT_SUCCESS: {
      let tdsp = null
      let isGeneralEnrollment = null
      let enrollment = action.payload
      if (enrollment) {
        tdsp = enrollment.tdsp
        if (tdsp !== undefined && tdsp !== null) {
          enrollment.formData = { ...enrollment.formData, tdsp }
          isGeneralEnrollment = tdsp.state_code !== 'TX'
          delete enrollment.tdsp
        }
      }
      return merge(state, {
        error: '',
        loading: false,
        isGeneralEnrollment,
        enrollment,
        completeEnrollment: true,
        tdspsWithOffers: [tdsp]
      })
    }

    case PRODUCT_OFFER_SUCCESS: {      
      return merge(state, {
        error: '',
        loading: false,
        enrollment: {
          ...state.enrollment,
          formData: {
            ...state.enrollment.formData,
            product : action.payload
          },
        },
      })
    }

    case SET_SHOULD_REDIRECT_TO_PAYMENT: {
      return merge(state, { shouldRedirectToPayment: action.payload })
    }

    case ENROLL_SUCCESS: {
      return merge(state, { finished: true })
    }

    case UPDATE_PROMO: {
      return merge(state, { promo: action.payload })
    }

    case ERROR_PROMO: {
      return merge(state, {
        promo: { ...state.promo, error: action.payload },
      })
    }

    case RESET_PROMO: {
      return merge(state, { promo: { unit: 'standard', value: 0, id: '0' } })
    }

    case SUBMIT_ZIPCODE_FORM: {
      return merge(state, {
        loading: true,
        serviceAddress: {
          postal_code: action.payload.postal_code,
        },
      })
    }

    case SAVE_REFERRAL_COOKIE_INFO: {
      const { name, code, referralType, eligibleStates, restrictions } = action.payload
      return merge(state, {
        referralInfo: {
          referralName: name,
          referralCode: code,
          referralType: referralType,
          eligibleStates,
          restrictions,
        },
      })
    }

    case SAVE_REFERRAL_INFO: {
      const { name, code, referralType } = action.payload
      return merge(state, {
        enrollment: {
          ...state.enrollment,
          formData: {
            ...state.enrollment.formData,
            referralName: name,
            referralCode: code,
            referralType: referralType,
          },
        },
      })
    }

    case SUBMIT_UPDATE_REFERRAL_NAME: {
      return merge(state, {
        referralInfo: {
          ...state.referralInfo,
          fullName: action.payload,
          updatedName: action.payload,
        },
      })
    }

    case TDSP_OFFERS_SUCCESS: {
      const { tdsps, zipcode } = action.payload

      // GX-1897 filter out DS referral codes based on eligible states
      // Note: TX flow will direct /offers to /zipcode on empty list
      //       NTX flow will direct to /offers indicating empty
      let tdspsWithOffers = []
      if (state.referralInfo.referralType === 'DS' && tdsps.length > 0) {
        // if eligibleStates is not set, redirect to DS referral page 
        if (state.referralInfo.eligibleStates === null || state.referralInfo.eligibleStates === undefined) {
          window.location.href = `${DS_REFERRAL_MAIN_PAGE_URL}/${state.referralInfo.referralCode}`
        }        
        tdspsWithOffers = tdsps.filter(t =>
          state.referralInfo.eligibleStates && state.referralInfo.eligibleStates.includes(t.state_code) 
        )

        // filter with restrictions if present in referral info        
        if (state.referralInfo.restrictions && state.referralInfo.restrictions.length > 0) {
          // loop restrictions and filter out offers
          for (const r of state.referralInfo.restrictions) {       
            // filter whitelisted states
            const filteredByState = tdspsWithOffers.filter(t => r.state === t.state_code)            
            for(const t of filteredByState) {
              const index = tdspsWithOffers.findIndex(tdsp => tdsp.ID === t.ID)
              // filter offers not equal to restriction service type
              const offers = tdspsWithOffers[index].offers.filter(o => r.service_type !== o.service_type)
              tdspsWithOffers[index].offers = offers;
            }
          }
        }

        // GX-2910: remove electricity offers for NY temporarily hardcoding it for now
        // it will be added to restrictions in the future
        const nyTdsps = tdspsWithOffers.filter(t => t.state_code === 'NY')  
        for(const nyTdsp of nyTdsps) {
          const offers = nyTdsp.offers.filter(o => o.service_type !== 'electricity')
          nyTdsp.offers = offers;
        }        
        
      } else {
        tdspsWithOffers = tdsps
      }

      // evaluate value for isGeneralEnrollment
      let isGeneralEnrollment = null
      if (tdsps.length > 0) {
        isGeneralEnrollment = tdsps[0].state_code !== 'TX'
      }

      return merge(state, {
        zipcode,
        tdspsWithOffers: tdspsWithOffers,
        loading: false,
        error: '',
        isGeneralEnrollment,
      })
    }

    case SELECT_OFFER: {
      const { offer, tdsp } = action.payload
      return merge(state, {
        inValidProduct: false,
        enrollment: {
          ...state.enrollment,
          error: '',
          serviceType: offer.service_type,
          formData: {
            ...state.enrollment.formData,
            product: { ...offer },
            tdsp,
            tdspName: tdsp.name,
          },
        },
      })
    }

    case NOT_VALID_PRODUCT: {
      return merge(state, {
        inValidProduct: action.payload,
      })
    }

    case METER_ALREADY_CONNECTED: {
      return merge(state, {
        meterAlreadyConnected: action.payload,
      })
    }

    case TOGGLE_IS_VALIDATING_ACCOUNT: {
      return merge(state, {
        isValidatingAccount: !state.isValidatingAccount
      })
    }

    case SAVE_PROMO_CODE: {
      const { promoCode } = action.payload
      return merge(state, {
        enrollment: {
          ...state.enrollment,
          formData: {
            ...state.enrollment.formData,
            promoCode,
          },
        },
      })
    }

    case SAVE_PARAMETERS: {
      return merge(state, {
        parameters: {
          ...state.parameters,
          ...action.payload
        },
      })
    }

    case DEPOSIT_REQUIREMENT: {
      const { depositAmount } = action.payload
      const lockCreditCheckInfo = state.enrollment.formData.agreeToCreditCheck

      return merge(state, { depositAmount, lockCreditCheckInfo })
    }

    case SET_VALIDATION_ERROR:
    case VERIFY_EMAIL_FAILURE:
    case UPDATE_ENROLLMENT_FAILURE:
    case WRONG_TDSP_ERROR:
    case METER_LOOKUP_FAILURE:
    case ENROLL_FAILURE:
    case TDSP_OFFERS_FAILURE:
    case COMPLETE_ENROLLMENT_FAILURE:
    case PRODUCT_OFFER_FAILURE:
      return merge(state, { error: action.payload, loading: false })

    case GET_USER_SUCCESS: {
      const {
        first_name,
        last_name,
        email,
        billing_address,
        phone,
        birthday,
      } = action.payload
      const ba = JSON.parse(billing_address)

      return merge(state, {
        enrollment: {
          ...state.enrollment,
          enrollmentType: 'add_meter',
          email,
          phone,
          formData: {
            ...state.enrollment.formData,
            firstName: first_name,
            lastName: last_name,
            billingAddress: ba,
            birthdate: birthday.split('T')[0],
            userAgreement: true,
            memberAgreement: true,
          },
        },
      })
    }
    case LOGIN_SUCCESS: {
      return merge(state, { login: true })
    }

    case USER_ALREADY_EXISTS: {
      return merge(state, { userAlreadyExists: action.payload })
    }

    case GET_CHASE_PAYMENT_UID_SUCCESS: {
      return merge(state, {
        enrollment: {
          ...state.enrollment,
          formData: {
            ...state.enrollment.formData,
            payment: {
              token: action.payload.uid,
              provider: PAYMENT_PROVIDERS.CHASE,
              merchantID: companyInfo.chaseMerchantID,
            },
          },
        },
      })
    }

    case SUBMIT_LOGIN_FORM: {
      return merge(state, { error: '' })
    }
    case CAPTURE_SIGNATURE: {
      // captured signature here is only used for client side data handling
      return merge(state, {
        ...state,
        signature: action.payload,
      })
    }

    case SUBMIT_CAPTURE_SIGNATURE_SUCCESS: {
      const { file_name, md5_hash } = action.payload
      // confirms in formData the successful meta data assocaited with the signature
      return merge(state, {
        enrollment: {
          ...state.enrollment,
          formData: {
            ...state.enrollment.formData,
            signature: { file_name, md5_hash },
          },
        },
      })
    }

    case SUBMIT_CHASE_PAYMENT_CUSTOM_FORM: {
      const {
        paperlessOptIn,
        marketingOptIn,
        TOSYRACEFLConfirm,
        preferredVerificationMethod,
      } = action.payload

      return merge(state, {
        enrollment: {
          ...state.enrollment,
          formData: {
            ...state.enrollment.formData,
            userAgreement: true,
            memberAgreement: true,
            marketingOptIn,
            paperlessOptIn,
            sms_opted_in: preferredVerificationMethod === 'sms',
            preferred_verification_method: preferredVerificationMethod,
            TOSYRACConfirm: TOSYRACEFLConfirm,
            EFLConfirm: TOSYRACEFLConfirm,
          },
        },
      })
    }

    case SUBMIT_NO_PAYMENT_REQUIRED_FORM: {
      const {
        autopay,
        paperlessOptIn,
        marketingOptIn,
        TOSYRACEFLConfirm,
        preferredVerificationMethod,
      } = action.payload

      return merge(state, {
        enrollment: {
          ...state.enrollment,
          formData: {
            ...state.enrollment.formData,
            autopay: autopay,
            userAgreement: true,
            memberAgreement: true,
            marketingOptIn,
            paperlessOptIn,
            sms_opted_in: preferredVerificationMethod === 'sms',
            preferred_verification_method: preferredVerificationMethod,
            // Assign TOS, YRAC and EFL to the same checkbox value
            TOSYRACConfirm: TOSYRACEFLConfirm,
            EFLConfirm: TOSYRACEFLConfirm,
          },
        },
      })
    }

    case SET_AUTO_PAY: {
      return merge(state, {
        enrollment: {
          ...state.enrollment,
          formData: {
            ...state.enrollment.formData,
            autopay: action.payload,
          },
        },
      })
    }

    case CHANGE_LOCALE: {
      const { locale } = action
      return merge(state, {
        enrollment: {
          ...state.enrollment,
          formData: {
            ...state.enrollment.formData,
            language: locale,
          },
        },
      })
    }

    case SET_ENROLLMENT_TYPE: {
      return merge(state, {
        enrollment: {
          ...state.enrollment,
          enrollmentType: action.payload,
        },
      })
    }

    case SUBMIT_VERIFY_EMAIL_FORM: {
      const { email, updateEmail } = action.payload
      if (updateEmail) {
        return merge(state, {
          enrollment: {
            ...state.enrollment,
            email: email,
          },
        })
      }
      return state
    }

    case SUBMIT_RENEWAL_NO_PAYMENT_REQUIRED_FORM: {
      let requestedStartDate = state.enrollment.formData.requestedStartDate
      if( state.enrollment.enrollmentType === 'renewal'){
        // don't send requested start date for renewal
        requestedStartDate = ''
      }
      return merge(state, {
        enrollment: {
          ...state.enrollment,
          formData: {
            ...state.enrollment.formData,
            userAgreement: true,
            memberAgreement: true,
            product: {
              ...state.enrollment.formData.product,
              start_date: state.enrollment.formData.requestedStartDate,
            },
            requestedStartDate,
          },
        },
      })
    }

    case GET_LMI_PROGRAM_TYPES: {
      const { lmi_types } = action.payload
      const lmiPrograms = {}
      lmi_types.forEach(program => {
        program.eligible_states.forEach(state => {
          if (!lmiPrograms[state]) {
              lmiPrograms[state] = []
          }
          lmiPrograms[state].push(program.name)
        })
      })
      return merge(state, { lmiPrograms })
    }

    default:
      return state
  }
}

export default reducer
